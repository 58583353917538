import { Column, type ColumnOptions } from '@antv/g2plot';
import { useDeepCompareEffect } from 'ahooks';
import { memo, useRef } from 'react';
import { useGlobalMyInfo } from '../myInfo/useGlobalMyInfo';

interface propType extends ColumnOptions {
	height: number;
}

export const ColumnComponent = memo((props: propType) => {
	const container = useRef(null);
	const ins = useRef<Column | null>(null);

	const [stateParams] = useGlobalMyInfo();
	const isShowLabel =
		stateParams?.globalSensitiveInformationHiding === false ?? true;

	const shouldData = props.data.map((a) => {
		a.value = a.value?.toNumber ? a.value.toNumber() : a.value;

		return a;
	});

	useDeepCompareEffect(() => {
		if (!shouldData) {
			return;
		}

		if (ins.current) {
			ins.current?.changeData(shouldData);

			// ins.current.render();
			return;
		}

		if (!container.current) {
			return;
		}

		const l = new Column(container.current, {
			...props,

			data: props.data.map((a) => {
				a.value = a.value?.toNumber ? a.value.toNumber() : a.value;

				return a;
			}),
			label: {
				// 可手动配置 label 数据标签位置
				position: 'middle', // 'top', 'bottom', 'middle',
				// 配置样式
				style: {
					fill: '#000000',
					opacity: isShowLabel ? 1 : 0,
				},
			},
			xAxis: {
				label: {
					autoHide: true,
					autoRotate: false,
					...(props?.xAxis?.label ?? {}),
					verticalLimitLength: 0.2
				},
			},
			meta: {
				value: {
					alias: '公共用电(与供电局差距)',
				},
			},
			annotations: isShowLabel ? props?.annotations : undefined
		});

		ins.current = l;
		l.render();
	}, [props.data]);

	// useDeepCompareLayoutEffect(() => {
	//   if (!container.current) {
	//     return;
	//   }
	//   ins.current?.render();
	// });

	return (
		<div
			style={{
				height: props.height,
			}}
			ref={container}
		/>
	);
});
